<template>
  <Layout>
    <div style="text-align: center; justify-content: center">
      <div
        class="base-class"
        style="text-align: center; justify-content: center"
      >
        <div style="background-color: white; width: 100%; text-align: left">
          <div style="display: flex">
            <el-button class="creat-button" @click="creatClick(1)"
              >+创建自定义接龙</el-button
            >
            <div class="search-item">
              <div>
                <i
                  class="el-icon-search"
                  style="
                    font-size: 25px;
                    margin-left: 18px;
                    margin-top: 10px;
                    color: #8e89aa;
                  "
                ></i>
              </div>

              <el-input
                placeholder="输入搜索关键词"
                v-model="filterName"
                class="input-with-search"
                clearable
              >
              </el-input>

              <el-button
                icon="el-icon-search"
                class="search-button"
                @click="searchClick()"
              ></el-button>
            </div>
          </div>

          <div
            class="base-class"
            style="margin-top: 10px; margin-bottom: 0px; margin-left: 30px"
          >
            <el-tabs v-model="activeName">
              <el-tab-pane
                v-for="item in mstencilArray"
                :key="item.classifyName"
                :label="item.classifyName"
                :name="item.classifyName"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>

      <div
        class="base-class"
        style="display: block; margin-top: 20px; min-height: 400px"
      >
        <div>
          <div style="display: flex; flex-wrap: wrap">
            <div
              class="model-item"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <el-image
                class="click-image"
                :src="imageUrl + item.cover_img"
                style="
                  background-color: bisque;
                  margin: 11px;
                  border-radius: 3px;
                "
              ></el-image>

              <div style="width: 200px">
                <p class="model-item-title">{{ item.name }}</p>
                <p class="model-item-count">{{ item.auxiliarytitle }}</p>
              </div>
              <div class="free-button" @click="creatClick(2,item)">免费试用</div>
            </div>
          </div>
        </div>
      </div>
      <el-pagination
        background
        layout="sizes,total,prev, pager, next"
        :page-sizes="[20, 50, 100, 200]"
        :total="tablePage.total"
        :current-page="tablePage.pageNum"
        :page-size="tablePage.pageSize"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </Layout>
</template>
  <script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { getHomePageTempleList } from "@/utils/api/activity";
import { imageUrl } from "@/utils/config/commonUrl.js";

// import { getUserInfo } from "@/utils/api/user";
/**
 * Basic-table component
 */
export default {
  page: {
    title: "接龙模版",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      imageUrl: imageUrl,
      filterName: "",
      activeName: "全部模版",
      mstencilArray: [],

      templateIndex: 3,
      classifyName: "",
      tableData: [],

      tablePage: {
        pageNum: 1, //
        pageSize: 20,
        total: 0,
      },
      searchText:'',
        routeParameter: {},
        isType: '',
    };
  },
  created() {
      this.routeParameter = this.$route.query;
      this.isType = this.routeParameter.isType;
    if (!localStorage.getItem("token")) {
      this.$router.replace("/login");
    }

    console.log(this.$route.query, "templateIndex");
    if (this.$route.query.templateIndex) {
      this.templateIndex = this.$route.query.templateIndex;
      if (this.templateIndex == "1") {
        this.classifyName = this.$route.query.classifyName;
        if (this.classifyName == '打卡'){
            this.classifyName = '';
            this.searchText = '打卡'
          }
        if (this.classifyName == '签到'){
            this.classifyName = '';
            this.searchText = '签到'
        }
      }
      this.getList(this.templateIndex);
    }

    console.log(this.classifyName, "this.classifyName ");
    if (this.classifyName != "") {
      this.activeName = this.classifyName;
      this.mstencilArray.push({
        classifyName: this.classifyName,
      });
    } else if (this.templateIndex == "3") {
      this.activeName = "通用接龙";
      this.mstencilArray.push({
        classifyName: "通用接龙",
      });
    } else if (this.templateIndex == "4") {
      this.activeName = "签到打卡";
      this.mstencilArray.push({
        classifyName: "签到打卡",
      });
    }
  },

  methods: {
    handleSizeChange(pageSize) {
      this.tablePage.pageNum = 1;
      this.tablePage.pageSize = pageSize;
      this.getList(this.templateIndex);
    },
    handlePageChange(currentPage) {
      this.tablePage.pageNum = currentPage;
      this.getList(this.templateIndex);
    },
    //搜索方法
    searchClick() {
      this.getList(this.templateIndex);
    },

    getList(templateIndex) {
      //获取列表数据
      getHomePageTempleList({
        pageSize: this.tablePage.pageSize,
        pageNum: this.tablePage.pageNum,
        templateIndex: templateIndex,
        filterName: this.filterName,
        classifyName: this.classifyName,
        name:this.searchText,
      }).then((res) => {
        console.log(res, "请求列表数据");
        if (res.status == 1) {
          this.tableData = res.data.list;
          this.tablePage.total = res.data.total;
        }
      });
    },

    // 创建自定义接龙
    creatClick(type,item) {
      console.log(item,'item');
      if (type == 1){
          if (this.isType == 109){
              this.$router.push({
                  path: "/solitaire",
                  query: { activityId: 2, selectType: "add", isType: 109},
              });
          }
          if (this.isType == 106){
              this.$router.push({
                  path: "/solitaire",
                  query: { activityId: 2, selectType: "add", isType: 106},
              });
          }

      } else {
          this.$router.push({
              path: "/solitaire",
          });
      }

    },
  },
};
</script>
  <style lang="scss" scoped>
.base-class {
  display: flex;
  min-width: 1236px;
}
.top-item {
  width: 397px;
  height: 120px;
  margin-right: 15px;
}
.top-image {
  width: 397px;
  height: 120px;
  position: absolute;
  z-index: -1;
}
.top-title {
  color: #ffffff;
  line-height: 33px;
  font-size: 24px;
  font-weight: 400;
  margin-left: 31px;
  margin-top: 18px;
  margin-bottom: 5px;
}

.top-count {
  color: #ffffff;
  line-height: 22px;
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 13px;
}
.creat-text {
  color: #ffffff;
  line-height: 22px;
  text-align: right;
  font-size: 14px;
  margin-right: 10px;
}

.click-item {
  width: 235px;
  height: 93px;
  margin-right: 12px;
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 3px;
  display: flex;
}
.click-image {
  width: 66px;
  height: 66px;
  margin-top: 13px;
  margin-left: 22px;
}

.click-title {
  font-size: 19px;
  color: #2b2f36;
  line-height: 28px;
  margin-top: 21px;
  margin-bottom: 12px;
  margin-right: 5px;
}

.click-creat {
  color: #a2a2a2;
  line-height: 20px;
  font-size: 13px;
}

.model-top {
  justify-content: space-between;
  display: flex;
  width: 1220px;
  margin-top: 20px;
}
.model-top-title {
  color: #2b2f36;
  line-height: 21px;
  font-size: 16px;
  font-weight: 500;
}
.model-top-more {
  color: #686868;
  line-height: 19px;
  font-size: 14px;
}
.model-item {
  width: 400px;
  height: 88px;
  background-color: #ffffff;
  border-radius: 4px;
  display: flex;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.model-item-title {
  color: #686868;
  line-height: 22px;
  font-size: 16px;
  margin-top: 18px;
  margin-bottom: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.model-item-count {
  color: #a3a7ad;
  line-height: 20px;
  font-size: 14px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.free-button {
  cursor: pointer;
  width: 80px;
  height: 31px;
  background-color: #1877ff;
  border-radius: 18px;
  text-align: center;
  line-height: 31px;
  color: #ffffff;
  font-size: 13px;
  margin-top: 30px;
  margin-left: 10px;
}
::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}
::v-deep .el-tabs__item {
  color: #a7adc6;
}
::v-deep .el-tabs__item.is-active {
  color: #323b5a;
}
::v-deep .el-tabs__item.hover {
  color: #323b5a;
}

.sub-tab-item {
  text-align: center;
  background-color: #eeeeee;
  color: #909090;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 15px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  cursor: pointer;
}
.creat-button {
  margin-left: 20px;
  margin-top: 15px;
  background-color: #1877ff;
  font-size: 15px;
  color: white;
  border-radius: 30px;
  text-align: center;
  line-height: 25px;
}
.search-item {
  margin-left: 20px;
  margin-top: 15px;
  background-color: #f8f8f8;
  width: 600px;
  height: 50px;
  border-radius: 20px;
  display: flex;
}
.search-button {
  width: 64px;
  height: 40px;
  background-color: #1877ff;
  font-size: 25px;
  color: white;
  border-radius: 30px;
  text-align: center;
  line-height: 25px;
  padding-top: 7px;
  margin-top: 5px;
}
.input-with-search {
  margin-left: 5px;
  margin-top: 5px;
  width: 483px;
}
.input-with-search {
  :deep(.el-input__inner) {
    border: 0px solid #dcdfe6;
    background-color: #f8f8f8;
  }
}

// el-input__inner

//选中 #B8D5FF   #1877FF
</style>